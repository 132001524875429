import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`"Pain of Regret or Pain of Discipline. Your Choice."`}</p>
    </blockquote>
    <p>{`I'm a software developer specializing in B2B SaaS products.`}</p>
    <p>{`I have worked on projects varying from Software-as-a-service applications with $5+ million in annual revenue to open-source free-to-use full-stack solutions.`}</p>
    <p>{`I LOVE developing applications using JavaScript and Python.  My favorite stack is `}<strong parentName="p">{`Django + Vue`}</strong>{`.`}</p>
    <p>{`Additionally, I am quite good with other development tools such as Linux, Git, Docker, Heroku and Firebase.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      