import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Pequity" link="https://www.getpequity.com/" bg="linear-gradient(to right, #3F72FF 0%, #31BFEA 100%)" mdxType="ProjectCard">
  Pequity simplifies the complex and time-consuming process of building a competitive, scalable, and
  equitable compensation program.
    </ProjectCard>
    <ProjectCard title="Haekka" link="https://haekka.com/" bg="linear-gradient(to right, #E6451E 0%, #d0be45 100%)" mdxType="ProjectCard">
  Haekka is a modern training platform for remote teams that love Slack.
    </ProjectCard>
    <ProjectCard title="Meeting Room 365" link="https://www.meetingroom365.com/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Meeting Room 365 is node.js application for managing displays at your office. Besides using node.js for
  development, I've had an oppurtunity to use various third-party APIs in order to push the product
  forward.
    </ProjectCard>
    <ProjectCard title="Escape Assist" link="https://escapeassist.com/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  EscapeAssist is a platform for Assisting Escape Room Businesses. EscapeAssist Uses classical MEAN Stack for Most of its
  products.
    </ProjectCard>
    <ProjectCard title="Get Django Saas" link="https://getdjangosaas.com/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Get Django Saas is a batteries-included SaaS starter kit that uses Django, Vue.js, and Heroku.
    </ProjectCard>
    <ProjectCard title="Kodeblok" link="https://kodeblok.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Blog where I talk about freelancing, developer lifestyle, and latest technology news.
  Built with Hugo and hosted on Firebase.
    </ProjectCard>
        

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      